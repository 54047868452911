module.exports = {
  defaultTitle: 'Adrien Mannocci',
  defaultDescription: 'I’m Adrien Developer & Freelance!',
  logo: 'https://amannocci.dev/favicon/favicon-512.png',
  author: 'Adrien Mannocci',
  url: 'https://amannocci.dev',
  legalName: 'Techcode SARL',
  about: 'I\'m a developer and freelance based in Sophia Antipolis, France. I\'m currently working as a Senior Software Engineer at Elastic and as a Freelancer. I write code fuelled by coffee and have a bug for all things about performances.',
  socialLinks: {
    twitter: 'https://www.twitter.com/AdrienMannocci',
    github: 'https://github.com/amannocci',
    linkedin: 'https://www.linkedin.com/in/amannocci/'
  },
  googleAnalyticsID: 'UA-69836547-2',
  themeColor: '#6b63ff',
  backgroundColor: '#6b63ff',
  address: {
    country: 'France'
  },
  contact: {
    email: 'adrien.mannocci@gmail.com'
  },
  foundingDate: '2022',
  recaptcha_key: '6LdSJ-MUAAAAAP35oh31iYrQyhKRUJlm4LkZVWvO',
};
